const inits = [
    { name: 'product_listing', el: 'div#site-product-listing'},
    { name: 'product_details', el: 'div#site-product-details-page'},
    { name: 'categorey_page', el: 'div#site-product-categories-page'},
    { name: 'product_overview', el: 'div#product-overview'},
    { name: 'featured_product_slider', el: 'div#site-featured-product-slider'},
]

inits.forEach(i => {
    if (document.querySelector(i.el)) {
        window[`${i.name}_vue_instance`] = new Vue({
            el: i.el
        })
    }
});